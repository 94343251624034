<template>
  <div :class="classes">
    <v-toolbar
      v-if="!hideToolbar"
      class="detail-view__toolbar rai-txt-titlebar"
      elevation="1"
    >
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <slot name="overflow" />
    </v-toolbar>
    <div class="detail-view__content">
      <slot />
    </div>
    <div class="detail-view__footer">
      <slot name="button" />
      <slot name="form" />
    </div>
  </div>
</template>
<script>
export default {
  name: "DetailViewDesktopGrid",
  props: {
    title: {
      type: String,
      default: "",
    },
    back: {
      type: String,
      default: "",
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "detail-view": true,
        "no-toolbar": this.hideToolbar,
      };
    },
  },
};
</script>
<style lang="scss">
.rai-app-sm,
.rai-app-md,
.rai-app-lg,
.rai-app-xl {
  .detail-view {
    height: 100%;
    display: grid;

    .detail-view__toolbar {
      z-index: 2;
      background-color: rgba(255, 255, 255, 0.8);
    }

    .detail-view__content {
      overflow-y: scroll;
      padding: 0 12px 0 12px;

      &::after {
        position: absolute;
        bottom: 35px;
        display: block;
        background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1)
        );
        margin-top: -50px;
        height: 50px;
        width: 75%;
        z-index: 1;
        content: "";
      }
    }
    .detail-view__footer {
      z-index: 2;
    }
  }
}

.rai-app-sm {
  .detail-view {
    grid-template-rows: 56px calc(100% - 56px - 56px) 52px;

    &.no-toolbar {
      grid-template-rows: calc(100% - 56px) 52px;
    }
  }
}
.rai-app-md,
.rai-app-lg,
.rai-app-xl {
  .detail-view {
    grid-template-rows: 64px calc(100% - 64px - 56px) 52px;

    &.no-toolbar {
      grid-template-rows: calc(100% - 56px) 52px;
    }
  }
}
</style>
